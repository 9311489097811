/* Add or modify styles as needed */

.landing-page {
    background-color: rgb(22, 114, 59);
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .carousel {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
 /* landingPage.css */

.carousel img {
  width: 85%;
  height: 85%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}

  .content {
    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px; /* Adjust the padding as needed */
  
    /* Add any other styles for your content section */
  }